html, body {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 100%;
  font-family: 'Avenir', serif;
}
*, *:before, *:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

.App {
  height: 100%;
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}

